@import url('https://fonts.googleapis.com/css2?family=Ubuntu+Sans+Mono:ital,wght@0,400..700;1,400..700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: IosevkaRegular;
	src: url(assets/fonts/iosevka-regular.ttf);
}

body {
	font-family: IosevkaRegular, "Ubuntu Sans Mono", monospace;
	background-image: url('assets/images/background.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
}

@layer components {
	body {
		@apply select-none text-xl;
	}
}
